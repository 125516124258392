<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    type: 'checkout' | 'invoice';
    onSuccessfulSubmit?: () => void;
  }>(),
  {
    onSuccessfulSubmit: () => {},
  },
);

const { handleAddressForm, form, errors, isPending } = usePostAddress(
  props.onSuccessfulSubmit,
);
</script>

<template>
  <form
    v-auto-animate
    class="space-y-4 text-sm font-normal text-bluegray-600"
    @submit.prevent="handleAddressForm"
  >
    <div class="flex flex-col gap-4 md:flex-row">
      <DefaultInput v-model="form.first_name" :error="errors.first_name">
        Imię <span class="text-errorColor">*</span>
      </DefaultInput>

      <DefaultInput v-model="form.last_name" :error="errors.last_name">
        Nazwisko <span class="text-errorColor">*</span>
      </DefaultInput>
    </div>

    <div class="flex gap-2">
      <PrimeCheckbox inputId="invoice" v-model="form.is_company" binary />
      <label for="invoice">{{
        type === 'checkout'
          ? 'Potrzebuję fakturę VAT'
          : 'Chcę dodać dane firmowe'
      }}</label>
    </div>

    <div v-if="form.is_company" class="flex flex-col gap-4 md:flex-row">
      <DefaultInput v-model="form.company_name" :error="errors.company_name">
        Nazwa firmy <span class="text-errorColor">*</span>
      </DefaultInput>

      <DefaultInput
        v-model="form.tax_number"
        :error="errors.tax_number"
        type="number"
      >
        NIP <span class="text-errorColor">*</span>
      </DefaultInput>
    </div>

    <div class="grid grid-cols-12 gap-4">
      <DefaultInput
        class="col-span-12 block"
        v-model="form.line1"
        :error="errors.line1"
      >
        Adres <span class="text-errorColor">*</span>
      </DefaultInput>

      <DefaultInput
        class="col-span-7 block"
        v-model="form.city"
        :error="errors.city"
      >
        Miasto <span class="text-errorColor">*</span>
      </DefaultInput>

      <label class="col-span-5 block space-y-1">
        <span> Kod pocztowy <span class="text-errorColor">*</span></span>
        <PrimeInputMask
          v-model="form.postal_code"
          mask="99-999"
          :invalid="!!errors.postal_code"
        />
        <small class="mt-1 text-left text-xs text-red-600">
          {{ errors.postal_code }}
        </small>
      </label>

      <DefaultInput
        v-if="type === 'checkout'"
        v-model="form.phone"
        class="col-span-12"
        :error="errors.phone"
        type="string"
      >
        Numer telefonu
      </DefaultInput>
    </div>

    <PrimeButton
      class="mr-auto"
      :class="{
        'w-full': type === 'checkout',
      }"
      type="submit"
      rounded
      :disabled="Object.keys(errors).length > 0"
      :loading="isPending"
    >
      {{ type === 'checkout' ? 'Dalej' : 'Zapisz zmiany' }}
    </PrimeButton>
  </form>
</template>
