import { fetcher } from '../fetcher';
import * as Sentry from '@sentry/vue';

const ADDRESSES_ENDPOINT = '/addresses';

const { STRIPE_URL } = useRuntimeConfig().public;

if (!STRIPE_URL) {
  Sentry.captureException(
    new ResponseError('STRIPE_URL environment variable is not set', 111),
  );

  showError({
    message:
      'Coś poszło nie tak przy pobieraniu danych. Spróbuj ponownie później.',
  });
}

type AddressCompany = Omit<AddressForm, 'company_name' | 'tax_number'> & {
  country_id: number;
  is_company: boolean;
  tax_number: string;
  company_name: string;
};

type AddressPersonal = Omit<
  AddressCompany,
  'tax_number' | 'company_name' | 'is_company'
> & {
  is_company: boolean;
  phone?: string;
};

export const addressesService = {
  getAddress: async () => {
    return await fetcher(`${ADDRESSES_ENDPOINT}/show`, {
      method: 'GET',
      customApiUrl: `${STRIPE_URL}`,
      schema: addressSchema,
    });
  },
  saveAddress: async (payload: AddressPersonal | AddressCompany) => {
    return await fetcher(`${ADDRESSES_ENDPOINT}`, {
      method: 'POST',
      customApiUrl: `${STRIPE_URL}`,
      body: payload,
    });
  },
  saveUserDetails: async (payload: AddressPersonal | AddressCompany) => {
    return await fetcher(`/personal-info`, {
      method: 'POST',
      body: {
        first_name: payload.first_name,
        last_name: payload.last_name,
        address: payload.line1,
        city: payload.city,
        zip_code: payload.postal_code,
        company: 'company_name' in payload ? payload.company_name : undefined,
        nip: 'tax_number' in payload ? payload.tax_number : undefined,
        is_company: payload.is_company,
        phone: payload.phone,
      },
    });
  },
};
