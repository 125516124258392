import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { toTypedSchema } from '@vee-validate/zod';
import { addressesService } from '@/utils/api/addressesService';
import { addressFormSchema } from '~/utils/schemas';
import type { AddressForm } from '~/utils/types';
import type { ResponseError } from '~/utils/responseError';

const FIELDS: (keyof AddressForm)[] = [
  'line1',
  'city',
  'is_company',
  'company_name',
  'first_name',
  'last_name',
  'tax_number',
  'postal_code',
  'phone',
];

const POLAND_COUNTRY_ID = 178;

export function usePostAddress(onSuccess?: () => void) {
  const toast = useToast();
  const { data: address } = useGetAddress();
  const queryClient = useQueryClient();

  const addressFormTypedSchema = computed(() => {
    return toTypedSchema(addressFormSchema);
  });

  const { form, handleSubmit, errors, resetForm } = useAppForm({
    fields: FIELDS,
    validationSchema: addressFormTypedSchema,
  });

  watch(
    address,
    () => {
      if (address.value) {
        FIELDS.forEach((field) => {
          if (field === 'is_company') {
            form[field] = !!address.value[field];

            return;
          }
          if (!address.value[field]) return;

          form[field] = address.value[field] || '';
        });
      }
    },
    { immediate: true },
  );

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: AddressForm) => {
      return Promise.all([
        addressesService.saveUserDetails({
          ...payload,
          country_id: POLAND_COUNTRY_ID,
        }),
        addressesService.saveAddress({
          ...payload,
          country_id: POLAND_COUNTRY_ID,
        }),
      ]);
    },
    onSuccess: () => {
      toast.add({
        severity: 'success',
        detail: 'Dane zostały zaktualizowane',
        life: 3000,
      });

      queryClient.invalidateQueries({ queryKey: ['address'] });
    },
    onError: (error: ResponseError) => {
      if (error.status === 422) {
        toast.add({
          severity: 'error',
          detail: error.message,
          life: 3000,
        });

        return;
      }

      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas aktualizacji danych',
        life: 3000,
      });
    },
  });

  const handleAddressForm = handleSubmit((values) => {
    if (Object.keys(errors.value).length > 0) return;

    mutate(
      {
        line1: values.line1,
        city: values.city,
        is_company: values.is_company,
        company_name: values.company_name,
        first_name: values.first_name,
        last_name: values.last_name,
        tax_number: values.tax_number,
        postal_code: values.postal_code,
        phone: values.phone,
      },
      {
        onSuccess,
      },
    );
  });

  return {
    mutate,
    isPending,
    form,
    handleAddressForm,
    errors,
    resetForm,
  };
}
